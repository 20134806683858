/* Reset CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

b {
  color: #3e3b3f;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Remove outline from focusable elements for mouse users only */
:focus:not(:focus-visible) {
  outline: none;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

/* Include a width and height for images to avoid layout shifts */
img {
  width: 100%;
  height: auto;
}

/* Make sure the elements are rendered consistently */
button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/* Make sure anchor tags don't receive focus when clicked */
a:focus {
  outline: none;
}


/* Navigation */


.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px;
  font-family: "Roboto", sans-serif;
  background-color: white;
  height: 90px;
  position: relative; /* Добавляем относительное позиционирование */
}

.navbar::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-image: linear-gradient(to right, #a46497, #2ea2cc);
}

.logo {
  position: absolute;

  padding: 30px 0px;
  left: 4%;
  top: 0;
}

@media screen and (max-width: 1550px) {
  .logo {
    position: relative;
    padding: 30px 20px;
    left: 0;
  }
}

.logo img{

  width: 200px;
  height: auto;
}

@media screen and (max-width: 1550px) {
  .logo img{
    max-width: 170px;
    height: auto;
  }
}

.logo:hover {
  transform: scale(1.05);
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.link {
  margin: 0 20px;
  text-decoration: none;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
  position: relative;
  transition: color 0.3s ease;
}

.link::after {
  content: '';
  display: block;
  width: 0;
  height: 4px;
  background-image: linear-gradient(to right, #a46497, #2ea2cc);
  transition: width 0.3s;
}

.link:hover::after {
  width: 100%;
}

/* .link:hover {
  color: #a46497;
} */

/* MainContent */

.wrapper {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}

.ratings-wrapper {
  max-width: 1350px;
  width: 100%;
  margin: 0 auto;
}

.main-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 20px;
  text-align: center;
  box-sizing: border-box;
}

.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 20px;
  text-align: center;
  box-sizing: border-box;
}

.main-content {
  position: relative;
  background-image: url('./img/bg-1.jpg');
  background-size: cover;
  background-position: center;
  height: 200px;
  padding: 200px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.content-overlay {
  position: relative;
  z-index: 2;
  text-align: center;
  color: white;
}

.ratings-content {
  position: relative;
  background-image: url('./img/bg-3.jpg');
  background-size: cover;
  background-position: center;
  height: 200px;
  padding: 200px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.main-title {
  font-weight: 700;
  font-size: 45px;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 30px;
}

.title {
  font-size: 45px;
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 700;
  padding-bottom: 30px;
}

.text {
  font-weight: 300;
  line-height: 1.5;
  font-size: 28px;
}

.join-button {
  background-image: linear-gradient(to right, #a46497, #2ea2cc);
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  text-transform: uppercase;
  letter-spacing: 1px;
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.join-button:hover {
  background-image: linear-gradient(to right, #2ea2cc, #a46497);
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.mt-100 {
  margin-top: 100px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-30 {
  margin-top: 30px;
}



.text-start {
  text-align: start;
}

.goals-section {
  background-image: url('./img/bg-2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 100px 0;
}

.goals-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  color: #fff;
}

.sub-text {
  font-weight: 200;
    line-height: 1.5;
    font-size: 28px;
}

.awards-section {
  padding-top: 100px;
  text-align: center;

}

.content-details-list {
  padding-top: 20px;
  list-style: none;
  font-size: 28px;
  padding-left: 0;
}

.arrow-icon-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #aa43c8;
  margin-right: 12px;
  flex-shrink: 0;
}

.arrow-icon-wrapper img {
  width: 20px;
  height: 20px;
}

.content-details-list li {
  display: flex;
  align-items: center; 
  margin-bottom: 25px;
  line-height: 1.2;
  text-align: left; 
}

.content-details-list li p {
  margin: 0; 
}

.awards-cont {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.bubble-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.awards-image {
  width: 300px;
  height: 300px;
  background-image: url('./img/prem.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.awards-image-01 {
  width: 250px;
  height: 250px;
  background-image: url('./img/prem.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.cont-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Jury */


.jury-content {
  position: relative;
  background-image: url('./img/bg-2.jpg');
  background-size: cover;
  background-position: center;
  height: 200px;
  padding: 200px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.jury-image {
  width: 300px;
  height: 300px;
  background-image: url('./img/nomination-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.logo-image {
  width: 100%;
  height: 150px;
  background-image: url('./img/olimp_3-03.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}



.logo-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.styled-list {
  list-style: none;
  padding: 0;
}

.styled-list li {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
  color: #3e3b3f;
  line-height: 1.3;
}

.list-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.list-item-container img {
  width: 80px;
  height: 80px;
}

.big-number {
  font-size: 74px;
  font-weight: bold;
  background: linear-gradient(to right, #a46497, #2ea2cc);
  -webkit-background-clip: text;
  color: transparent;
}

.jury-list {
  list-style: none;
  padding: 0;
  text-align: center;
}

.jury-list img{
  width: 70px;
  height: 70px;
  margin-right: 15px;
}

.jury-list li {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
  color: #3e3b3f;
  line-height: 1.3;
  display: flex;
  align-items: center;
}

.jury-number {
  font-size: 74px;
  font-weight: bold;
  background: linear-gradient(to right, #a46497, #2ea2cc);
  -webkit-background-clip: text;
  color: transparent;
  padding: 10px 15px;
  border-radius: 50%;
  margin-right: 15px;
}

.jury-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.jury-buttons button {
  background-image: linear-gradient(to right, #a46497, #2ea2cc);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.jury-buttons button:hover {
  background-image: linear-gradient(to right, #2ea2cc, #a46497);
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2)
}

.jury-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.year-title {
  font-size: 45px;
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 700;
  text-align: center;
  color: #3e3b3f;
  padding-bottom: 50px;
}

.jury-details-list {
  padding-top: 20px;
  list-style: none;
  font-size: 24px;
  font-weight: 300;
  margin-right: 20px;
}

.jury-details-list li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  line-height: 1.2;
}


.jury-item {
  display: flex;
  margin-bottom: 100px;
}

.image-container {
  margin-right: 50px;
}

.jury-title {
  font-weight: 200;
  font-size: 36px;
  text-transform: uppercase;
  position: relative;
}

.jury-title::after {
  content: '';
  position: absolute;
  border-bottom: 3px solid #aa43c8;
  width: 150px;
  bottom: -10px;
  left: 0;
}
.jury-country {
  font-size: 24px;
  font-weight: 400;
  margin-top: 30px;
}

.text-container {
  max-width: 800px;
}


.text-container {
  flex: 1;
  text-align: left;
}

.jury-img  {
  width: 350px;
  height: 515px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.jury-img-2  {
  width: 400px;
  height: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.jury-card img:hover {
  transform: scale(1.05);
}

.position-image {
  width: 250px;
  height: 250px;
  background-image: url('./img/nomination-icon.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}


/* Form */

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.form-container {
  max-width: 450px;
  width: 100%;
  margin: auto;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  background-color: #ffffff;
}

.form-header h2 {
  text-align: center;
    font-weight: 600;
    font-size: 35px;
    text-transform: uppercase;
    color: #333;
  margin-bottom: 10px;
}

.form-header p {
  font-size: 20px;
  text-align: center;
  color: #666;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 8px;
  color: #495057;
  font-size: 16px;
}

.form-input {
  padding: 12px 20px;
  border: 2px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f8f9fa;
  transition: border-color 0.2s ease-in-out;
}

.form-input:focus {
  border-color: #a46497;
  outline: none;
}

.form-submit-button {
  padding: 12px 20px;
  font-weight: 700;
  font-size: 18px;
  background-image: linear-gradient(to right, #a46497, #2ea2cc);;
  color: white;
  border: none;
  letter-spacing: 1px;
  border-radius: 20px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.form-submit-button:hover, .form-submit-button:focus {
  background-image: linear-gradient(to right, #2ea2cc, #a46497);
  transform: scale(1.05);
}

.form-submitted-message {
  font-size: 24px;
  color: #38c172;
}


/* Win */

.win{
  margin: 100px 0px;
}

.win-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.win-card {
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title-w {
  font-size: 45px;
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 700;
  color: #3e3b3f;
  padding-bottom: 30px;
}

.win-title {
  display: flex;
  align-items: center;
  font-size: 36px;
  font-weight: 600;
  color: #3e3b3f;
  text-align: start;
  margin-bottom: 30px;
}

.win-title img{
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

.win-sub-title {
  font-weight: 200;
  font-size: 32px;
  text-transform: uppercase;
}
/* Photos */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 350px);
  grid-gap: 40px;
}

.photo-grid img {
  width: 100%;
  height: auto;
}

.button-container {
  margin-top: 20px;
}

.custom-button {
  padding: 10px 20px;
  margin-right: 10px;
  font-size: 16px;
  background-image: linear-gradient(to right, #a46497, #2ea2cc);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-button:last-child {
  margin-right: 0;
}

.custom-button:hover {
  background-image: linear-gradient(to right, #2ea2cc, #a46497);
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.custom-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Articles */

.article-list {
  padding: 50px 20px;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.article-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.article-item {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s, border-color 0.3s;
}

.article-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background: linear-gradient(to right, rgba(164, 100, 151, 0.3), rgba(46, 162, 204, 0.3));
  transition: opacity 0.3s, transform 0.3s;
  opacity: 0;
}

.article-item:hover::before {
  opacity: 1;
  transform: scale(1.05);
}

.article-item:hover {
  transform: scale(1.02);
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
}

.article-item a{
  text-decoration: none;
  color: inherit;
}

.article-item h2 {
  font-size: 28px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}

.article-item .date {
  font-size: 16px;
  color: #999;
  margin-bottom: 10px;
}

.article-item .author {
  font-size: 18px;
  color: #666;
  margin-top: 20px;
  text-align: right;
}

.secondary-title, .secondary-sub-title {
  text-align: center;
  margin-bottom: 20px;
}

.secondary-title {
  font-size: 36px;
  color: #333;
  font-weight: 700;
}

.secondary-sub-title {
  font-size: 28px;
  color: #777;
}

/* Article Detail */
.article-detail {
  padding: 50px 20px;
  background: #fff;
  max-width: 1300px;
  margin: 0 auto;
}

.back-link {
  display: inline-block;
  margin-bottom: 20px;
  color: #2ea2cc;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

.back-link:hover {
  color: #a46497;
}

.article-title {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 700;
}

.article-detail .date {
  font-size: 16px;
  color: #999;
  margin-bottom: 20px;
}

.article-detail .author {
  font-size: 20px;
  color: #666;
  margin-top: 40px;
  text-align: right;
}

.article-detail p {
  font-size: 20px;
  color: #444;
  line-height: 1.8;
  margin-bottom: 20px;
  font-family: 'Georgia', serif;
}

.article-sub-list {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.article-sub-list li {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.8;
  color: #444;
  font-family: 'Georgia', serif;
}

.article-sub-image {
  padding: 20px 0px 40px 0px;
}

/* Contacts */
.contact-page {
  max-width: 1000px;
  margin: 100px auto;
  padding: 40px;
  box-shadow: inset 0 -5px 10px rgba(0,0,0,0.1);
  background: linear-gradient(to right, rgba(164, 100, 151, 0.6), rgba(46, 162, 204, 0.6));
  border-radius: 15px;

}

.contact-header {
  padding: 20px;

}

.contact-header h1 {
  font-size: 28px;
  text-align: center;
  color: #333;
  margin: 0;
  padding: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.contact-content {
  padding: 20px;
  border-radius: 0 0 15px 15px;
}

.contact-block {

  padding: 20px;
  margin-top: 20px;
  border-left: 5px solid rgba(46, 162, 204, 0.6);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.contact-block:hover {
  transform: translateX(10px);
  border-left: 10px solid rgba(46, 162, 204, 0.6);
}

.contact-block h2 {
  font-size: 26px;
  font-weight: 700;
  color: #333;
  margin-bottom: 15px;
}

.contact-block p {
  font-size: 22px;
  line-height: 1.6;
  color: #333;
  margin: 5px 0;
}

.contact-block a {
  color: #3e3b3f;

  text-decoration: underline;
  transition: color 0.3s ease;
}

.contact-block a:hover {
  color: rgba(46, 162, 204, 0.8);
  text-decoration: underline;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.partner-images {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.partner-card {
  width: 260px;
  height: 160px;
  box-shadow: inset 0 -5px 10px rgba(0,0,0,0.1);
  background: linear-gradient(to right, rgba(164, 100, 151, 0.6), rgba(46, 162, 204, 0.6));
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

}

.partner-image {
  width: 200px;
  height: auto;
  margin: 10px;
}

/* Footer */

.footer {
  background-color: #262626;
  color: white;
  display: flex;
  justify-content: space-around;
  padding: 40px 20px;
  flex-wrap: wrap;
  margin-top: 40px;
}

.footer-container {
  margin: 0 auto;
  padding: 15px;
  text-align: center;
}

.footer-container strong {
  display: block;
  margin-bottom: 20px;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 600;
}

.footer-container p {
  font-size: 20px;
  font-weight: 300;
}

.address {
  margin-bottom: 20px;
}

.phone {
  margin-bottom: 20px;
}
